import Vue from 'vue'

const onTimeHrsKeyDow = (event) => {
  const specialKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'ArrowLeft',
    'ArrowRight'
  ]

  if (specialKeys.includes(event.key)) {
    return
  }

  const isDigit = event.key >= '0' && event.key <= '9'
  const isColon = event.key === ':' && event.target.value.indexOf(':') === -1

  if (!isDigit && !isColon) {
    event.preventDefault()
  }
}

const onTimeHrsBlur = (objName, field, value) => {
  Vue.set(this[objName], field, timeHrsAutoConvert(value))
}

const timeHrsAutoConvert = (value, return_int = false) => {
  let _value = value

  if (_value || _value == 0) {
    _value = value.toString()
    if (_value.includes(':')) {
      const [hours, minutes] = _value.split(':').map(Number)
      _value = `${hours.toString().padStart(2, '0')}:${Math.min(minutes, 59)
        .toString()
        .padStart(2, '0')}`
    } else {
      const totalMinutes = parseInt(_value, 10)
      const hours = Math.floor(totalMinutes / 60)
        .toString()
        .padStart(2, '0')
      const minutes = (totalMinutes % 60).toString().padStart(2, '0')
      _value = `${hours}:${minutes}`
    }

    if (return_int) {
      if (_value.indexOf(':') > -1) {
        const [hours, minutes] = _value.split(':').map(Number)
        _value = hours * 60 + minutes
      } else {
        _value = 0
      }
    }
  }

  return _value
}

export { onTimeHrsKeyDow, onTimeHrsBlur, timeHrsAutoConvert }
