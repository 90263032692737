<script setup>
import { computed, ref, onMounted, watch } from 'vue'
import { ValidationProvider } from 'vee-validate'
import request from '@utils/request'
import _ from 'lodash'

const props = defineProps({
  view: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  itemText: {
    type: String,
    default: 'name'
  },
  url: {
    type: String,
    default: ''
  },
  // items: {
  //   type: Array,
  //   default: () => []
  // },
  value: {
    type: Object,
    default: () => null
  },
  pageSize: {
    type: Number,
    default: 30
  },
  filterObject: {
    type: Function,
    default: () => {
      return 'name'
    }
  },
  binds: {
    type: Object,
    default: () => ({})
  },
  events: {
    type: Object,
    default: () => ({})
  },
  // A function that expects an object that's compatible with axios request:
  requestOptions: {
    type: Function,
    default: null
  },
  // A function that expects an array of results from the server:
  responseTransformer: {
    type: Function,
    default: null
  },
  /**
   * validation name
   */
  name: {
    type: String,
    required: true
  },
  /**
   * validation rules
   */
  rules: {
    type: [String, Object],
    default: ''
  },
  // used to display the selected value of select-server
  nameAccessor: {
    type: String,
    default: null
  },
  isUpperCase: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['input'])

const validation = ref(null)
const loading = ref(false)
const search = ref('')
const page = ref(0)
const items = ref([])
const count = ref(null)

const isRequired = computed(() => {
  return props.rules instanceof Object
    ? props.rules.required
    : props.rules.split('|').includes('required')
})

const onInput = (value) => {
  /**
   * Set input for v-model
   * @type {Event}
   */
  if (props.isUpperCase) {
    value = value.toUpperCase()
  }

  validation.value.syncValue(value)
  validation.value.validate()
  emit('input', value)
}

const onFocus = () => {
  page.value = 0
  count.value = null
  items.value = []
  if (!props.view && props.value) {
    items.value = [props.value]
  } else {
    items.value = []
  }
}

const onIntersect = () => {
  if (count.value != null && items.value.length >= count.value) {
    return
  }
  page.value += 1
  fetchItems()
}

const fetchItems = async () => {
  const offset = (page.value - 1) * props.pageSize
  const params = {
    offset: offset
  }
  if (search.value) {
    params.search = search.value
  }
  let requestOptions = {
    method: 'get',
    url: props.url,
    params: {
      ...params,
      limit: props.pageSize
    }
  }
  if (props.requestOptions) {
    requestOptions = {
      ...requestOptions,
      ...(props.requestOptions({
        ...params,
        page: page.value,
        pageSize: props.pageSize
      }) || {})
    }
  }
  // const requestOptions = props.requestOptions?.apply({
  //   ...params,
  //   page: page.value,
  //   pageSize: props.pageSize
  // }) || {
  //   method: 'get',
  //   url: props.url,
  //   params: {
  //     ...params,
  //     limit: props.pageSize
  //   }
  // }
  if (requestOptions.url) {
    try {
      loading.value = true
      const response = await request(requestOptions)
      const resultItems =
        props.responseTransformer?.call(undefined, response.data) ||
        response.data.results
      count.value = response.data.count
      if (resultItems.length) {
        items.value.push(...resultItems)
      }
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  } else {
    console.error('URL is required')
  }
}

const readonlyDisplay = computed(() => {
  return props.nameAccessor
    ? _.get(props.value, props.nameAccessor)
    : props.value?.name
})
watch(
  () => props.value,
  () => {
    if (!props.view && props.value) {
      items.value = [props.value]
    }
  }
)
onMounted(() => {
  if (!props.view && props.value) {
    items.value = [props.value]
  }
})
</script>

<template>
  <div class="app-input-wrapper">
    <template v-if="view">
      <h3 class="app-input--view-label">{{ label }}</h3>
      <div class="mt-1 app-input--view-value">
        {{ readonlyDisplay }}
      </div></template
    >
    <template v-else>
      <validation-provider
        class="app-input"
        ref="validation"
        v-slot="{ errors }"
        :name="name"
        :rules="rules"
      >
        <v-autocomplete
          clearable
          return-object
          :label="label"
          :item-text="itemText"
          :items="items"
          :loading="loading"
          :search-input.sync="search"
          :disabled="disabled"
          :error-messages="errors"
          :value="value"
          :filter="filterObject"
          v-bind="binds"
          v-on="events"
          autocomplete="off"
          @input="onInput"
          @focus="onFocus"
        >
          <template v-slot:label>
            <div>
              {{ label }}
              <small class="error--text" v-if="isRequired">*</small>
            </div>
          </template>

          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <template v-if="!search">{{ $t('label.typeSearch') }}</template>
                <template v-else>{{ $t('label.noData') }}</template>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:append-item>
            <div v-intersect="onIntersect" class="pa-4 teal--text"></div>
          </template>
        </v-autocomplete>
      </validation-provider>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
