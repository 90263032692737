<template>
  <app-menu-form
    model="auditplanriskmatrix"
    :headers="riskMatrixItemHeader"
    :serverItems="riskMatrixItemItems"
    :clientItems="riskMatrixItemItems"
    :loading="loading"
    :serverItemsLength="riskMatrixItemServerItemsLength"
    hide-edit
    hide-delete
    :events="eventHandlers"
  >
    <template v-slot:top>
      <app-menu-form-top
        title="Risk Matrix"
        :dialog="dialogRiskMatrix"
        dialogMaxWidth="1500px"
        :isActivator="false"
      >
        <v-card>
          <v-card-text class="pt-4">
            <v-toolbar-title>Task</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <app-table
              server-side
              app="audit"
              model="task"
              :headers="riskTaskItemHeader"
              :serverItems="riskTaskItemItems"
              :clientItems="riskTaskItemItems"
              :loading="loading"
              :server-items-length="riskTaskItemServerItemsLength"
              :isSelecteable="false"
              @server="getRiskTask"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeRiskMatrix">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from './AppMenuFormTop.vue'

export default {
  name: 'app-risk-matrix',
  components: {
    AppTable,
    AppMenuForm,
    AppMenuFormTop
  },
  data() {
    return {
      loading: false,

      dialogRiskMatrix: false,
      riskMatrixProfileId: null,
      riskMatrixItemItems: [],
      riskMatrixItemServerItemsLength: 0,
      riskTaskItemItems: [],
      riskTaskItemServerItemsLength: 0
    }
  },
  computed: {
    riskMatrixItemHeader() {
      return [
        {
          text: this.$t('fields.riskProfile'),
          value: 'name',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.task'),
          value: 'audit_risk_profile_task_count',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    riskTaskItemHeader() {
      return [
        {
          text: this.$t('fields.reference'),
          value: 'reference',
          hideFilter: true
        },
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.sectionName'),
          value: 'section_name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.preparedBy'),
          value: 'prepare_by',
          hideFilter: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewedBy'),
          value: 'review_by',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          hideFilter: true
        },
        {
          text: this.$t('label.preAudit'),
          value: 'is_pre_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('label.audit'),
          value: 'is_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('label.postAudit'),
          value: 'is_post_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getRiskMatrixItemItems,
        view: this.onViewRiskTask
      }
    }
  },
  methods: {
    getRiskMatrixItemItems() {
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: this.isPlan
            ? `audit/audit-plan-risk-matrix/?audit_plan_header_id=${this.$route.params.id}&audit_header_id=0`
            : `audit/audit-plan-risk-matrix/?audit_header_id=${this.$route.params.id}&audit_plan_header_id=0`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.riskMatrixItemServerItemsLength = data.count
          this.riskMatrixItemItems = data.results
        })
      }
      this.loading = false
    },
    onViewRiskTask(item) {
      this.riskMatrixProfileId = item.id
      this.getRiskTask()
      this.dialogRiskMatrix = true
    },
    getRiskTask() {
      if (
        this.riskMatrixProfileId !== null &&
        this.riskMatrixProfileId !== undefined
      ) {
        this.$api({
          method: 'get',
          url: this.isPlan
            ? `audit/audit-plan-risk-matrix/detail/?risk_profile_id=${this.riskMatrixProfileId}&audit_plan_header_id=${this.$route.params.id}&audit_header_id=0`
            : `audit/audit-plan-risk-matrix/detail/?risk_profile_id=${this.riskMatrixProfileId}&audit_header_id=${this.$route.params.id}&audit_plan_header_id=0`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.riskTaskItemServerItemsLength = data.count
          this.riskTaskItemItems = data.results
        })
      }
      this.loading = false
    },
    closeRiskMatrix() {
      this.riskTaskItemItems = []
      this.riskTaskItemServerItemsLength = 0
      this.dialogRiskMatrix = false
    }
  }
}
</script>
