// https://martin.ankerl.com/2009/12/09/how-to-create-random-colors-programmatically/
// HSV values in [0..1[
// returns [r, g, b] values from 0 to 255
function hsvToRgb(h, s, v) {
  let h_i = Math.floor(h * 6)
  let f = h * 6 - h_i
  let p = v * (1 - s)
  let q = v * (1 - f * s)
  let t = v * (1 - (1 - f) * s)
  let r, g, b

  if (h_i === 0) {
    r = v
    g = t
    b = p
  } else if (h_i === 1) {
    r = q
    g = v
    b = p
  } else if (h_i === 2) {
    r = p
    g = v
    b = t
  } else if (h_i === 3) {
    r = p
    g = q
    b = v
  } else if (h_i === 4) {
    r = t
    g = p
    b = v
  } else if (h_i === 5) {
    r = v
    g = p
    b = q
  }

  return [Math.floor(r * 256), Math.floor(g * 256), Math.floor(b * 256)]
}
function rgbaToHex(r, g, b, a) {
  const toHex = (n) => {
    const hex = n.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  return '#' + toHex(r) + toHex(g) + toHex(b) + toHex(Math.round(a * 255))
}

const golden_ratio_conjugate = 0.618033988749895
function randomColor() {
  let rand = Math.random()
  rand += golden_ratio_conjugate
  rand %= 1
  return hsvToRgb(rand, 0.5, 0.95)
}

export { hsvToRgb, rgbaToHex, randomColor }
