<script setup>
import { ref } from 'vue'

const props = defineProps({
  panelKey: String,
  title: String,
  value: {
    type: Array,
    default: () => []
  },
  list: {
    type: Array,
    default: () => []
  }
})
const search = ref('')
const isSearchFocused = ref(false)
const emit = defineEmits(['input'])
const onChange = (event) => {
  emit('input', event)
}
const selectAll = () => {
  emit(
    'input',
    props.list.map((item) => item.id)
  )
}
const selectNone = () => {
  emit('input', [])
}
</script>

<template>
  <v-expansion-panel :key="panelKey">
    <v-expansion-panel-header>
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-toolbar flat>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <transition name="fade">
              <v-btn
                icon
                v-on="on"
                @click="selectAll"
                v-show="!isSearchFocused"
              >
                <v-icon>mdi-checkbox-outline</v-icon>
              </v-btn>
            </transition>
          </template>
          <span>Select all</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <transition name="fade">
              <v-btn
                icon
                v-on="on"
                @click="selectNone"
                v-show="!isSearchFocused"
              >
                <v-icon>mdi-checkbox-blank-outline</v-icon>
              </v-btn>
            </transition>
          </template>
          <span>Select none</span>
        </v-tooltip>
        <v-text-field
          v-model="search"
          dense
          clearable
          flat
          hide-details
          prepend-inner-icon="mdi-magnify"
          label="Search"
          @focus="isSearchFocused = true"
          @blur="isSearchFocused = false"
        ></v-text-field>
      </v-toolbar>

      <v-data-iterator
        :items="list"
        item-key="id"
        :items-per-page="5"
        disable-sort
        :search="search"
      >
        <template v-slot:item="{ item }">
          <v-checkbox
            dense
            :input-value="value"
            @change="onChange"
            :label="item.name"
            :value="item.id"
          >
            <template v-slot:label>
              <slot name="label" :item="item"></slot>
            </template>
            <template v-slot:append>
              <slot name="append" :item="item"></slot>
            </template>
          </v-checkbox>
        </template>
      </v-data-iterator>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<style scoped lang="scss">
.fade-enter-active {
  transition: opacity 0.25s;
}

.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
