<template>
  <app-menu-form
    :mode="mode"
    :headers="taskItemHeader"
    :serverItems="auditTasks"
    :clientItems="auditTasks"
    :loading="loading"
    :serverItemsLength="auditTasksLength"
    :hideEdit="hideEdit"
    :hideDelete="hideDelete"
    :events="eventHandlers"
  >
    <template v-slot:top>
      <div class="app-list-view">
        <div class="app-list-view--header">
          <v-toolbar flat>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <slot name="actions">
              <slot name="prefix-actions" />
              <v-btn
                color="primary"
                dark
                v-show="!hideEdit"
                @click="onCreateTask"
              >
                New Item
              </v-btn>
              <v-btn
                v-show="selectedTasks.length && !hideDelete"
                color="error"
                @click="deleteTask(selectedTasks)"
              >
                {{ $t('btn.delete') }}
              </v-btn>
              <slot name="suffix-actions" />
            </slot>
          </v-toolbar>
        </div>
      </div>
    </template>
  </app-menu-form>
</template>

<script>
import AppMenuForm from '@components/AppMenuForm.vue'

export default {
  name: 'app-audit',
  components: {
    AppMenuForm
  },
  props: {
    title: {
      type: String,
      required: true
    },
    taskItemHeader: {
      type: Array,
      required: true
    },
    auditTasks: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    auditTasksLength: {
      type: Number,
      default: 0
    },
    mode: {
      type: String
    },
    hideEdit: {
      type: Boolean,
      default: true
    },
    hideDelete: {
      type: Boolean,
      default: true
    },
    serverFetch: {
      type: Function,
      required: true
    },
    deleteTask: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      selectedTasks: []
    }
  },
  computed: {
    eventHandlers() {
      return {
        server: this.serverFetch,
        delete: this.deleteTask,
        edit: this.onEditTask,
        view: this.onEditTask,
        viewAlt: this.onEditTaskAlt
      }
    }
  },
  methods: {
    onEditTask(item) {
      this.$emit('editTask', item)
    },
    onEditTaskAlt(item) {
      this.$emit('editTaskAlt', item)
    },
    onCreateTask() {
      this.$emit('createTask')
    }
  }
}
</script>
