<script setup>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import luxonPlugin from '@fullcalendar/luxon3'
import interactionPlugin from '@fullcalendar/interaction'
import { nextTick, onMounted, ref } from 'vue'

const props = defineProps({
  eventSources: {
    type: Array,
    default: () => []
  },
  initialDate: {
    type: String,
    default: null
  }
})
const emit = defineEmits(['eventClick', 'dateClick'])

const onEventClick = (info) => {
  emit('event-click', info)
}
const onDateClick = (info) => {
  emit('date-click', info)
}
const calendarOptions = {
  plugins: [dayGridPlugin, timeGridPlugin, luxonPlugin, interactionPlugin],
  initialView: 'dayGridMonth',
  initialDate: props.initialDate,
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay'
  },
  views: {
    dayGrid: {
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: true,
        meridiem: 'short'
      }
    }
  },
  eventSources: props.eventSources,
  eventClick: onEventClick,
  dateClick: onDateClick
}
const cal = ref(null)
defineExpose({
  calendar: cal
})
onMounted(() => {
  // otherwise the calendar will not be displayed correctly
  nextTick(() => {
    cal.value.calendar.updateSize()
  })
})
</script>

<template>
  <FullCalendar class="app-calendar" ref="cal" :options="calendarOptions" />
</template>

<style lang="scss">
.app-calendar {
  .fc-daygrid-dot-event {
    .fc-event-time {
      color: #333333;
    }
    .fc-event-title {
      color: #333333;
      font-weight: 600;
    }
    &.planned {
      .fc-daygrid-event-dot {
        border-width: 1px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }
}
</style>
